<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <actor-role-update :id="id"/>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ActorRoleUpdate from './ActorRoleUpdate.vue'

export default {
  components: {
    VuexBreadcrumb,
    ActorRoleUpdate
  },
  props: {
    id: String
  }
}
</script>